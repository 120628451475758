import { first } from 'lodash';
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloManagersActions } from '../../actions/clo-managers.actions';
import IconSVG from '../../styles/svg-icons';
import { AppState } from '../../types/state/AppState';
import { CloManagersState } from '../../types/state/CloManagersState';
import { compareDatesDesc } from '../../utils/compare.utils';
import CloManagersContext from './cloManagersContext';
import { CloManager } from '../../types/clo-managers/CloManager';
import { CloManagerAlertsButton } from './CloManagerAlertsButton';
import { UserConfigState } from '../../types/state/UserConfigState';
import { CloManagerLabels } from './CloManagerLabels';
import { roles } from '../../constants';
import { ShowFor } from '../access';
import { ScheduleMeetingPopup } from './scheduleMeetingPopup/ScheduleMeetingPopup';
import { ActionBlocker } from '../access/ActionBlocker';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { withTurnedOnAlerts } from '../../utils/amr-pipeline.utils';
import { user } from '../../user';
import { Preloader } from '../common';
import { useDownloadDocuments } from '../../effects/useDownloadDocuments';
import { cloManagersService } from '../../services/clo-managers.service';

interface HeaderProps {
    company: CloManager;
    edit: boolean;
    onEdit?: () => void;
    onSave?: () => void;
    onCancel?: () => void;
}

const Header = ({ company, edit, onEdit, onSave, onCancel }: HeaderProps) => {
    const dispatch = useDispatch();

    const [showScheduleMeetingPopup, setShowScheduleMeetingPopup] = useState(false);

    const { canEdit, ownCollateralManagersCompany } = useContext(CloManagersContext);
    const { isUpdating } = useSelector<AppState, CloManagersState>((state: AppState) => state.cloManagers);
    const { selectedManager, bwicCloManagers } = useSelector<AppState, CloManagersState>((state: AppState) => state.cloManagers);
    const userCompany = useSelector((state: AppState) => state.issuanceMonitor.amrPipelineCommon.userCompany);

    const hasAlertAccess = user.hasAllFeatures(SubscriptionFeature.ManagerProfileManagerRelatedAlerts, SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts);

    const investmentTeamMeetingContacts = selectedManager?.details?.investmentTeam.items
        ?.filter(c => c.meetingContact && c.email)
        .map(c => c.email) || [];

    const platformUsersMeetingContacts = bwicCloManagers?.filter(c => c.meetingContact && c.email).map(c => c.email) || [];

    const defaultEmailList = [...investmentTeamMeetingContacts, ...platformUsersMeetingContacts];

    const managerPresentationFile = first(
        selectedManager?.details?.managerPresentationFiles?.sort((a, b) =>
            compareDatesDesc(b.uploadTime, a.uploadTime),
        ),
    );

    const { handleDownload: downloadManagerPresentationFile, isDownloading: isManagerPresentationDownloading } = useDownloadDocuments({
        downloadRequest: cloManagersService.downloadManagerPresentationFile,
        requestArgs: [company.referenceName, managerPresentationFile?.referenceName || ''],
        fileNameForSave: managerPresentationFile ? managerPresentationFile.name : null,
    });

    const alertConfigs = useSelector<AppState, UserConfigState>((state: AppState) => state.imUserConfig);

    const isAlertOn = withTurnedOnAlerts([
        ...(ownCollateralManagersCompany ? alertConfigs.cloManagerAnalyticsAlert.value : []),
        ...(hasAlertAccess ? alertConfigs.cloManagerIssuanceMonitorAlert.value : []),
        ...(hasAlertAccess ? alertConfigs.cloManagerArrangerPipelineAlert.value : []),
        ...alertConfigs.cloManagerBwicAlert.value,
        ...alertConfigs.cloManagerDealerInventoryAlert.value,
    ], selectedManager?.referenceName);

    const renderScheduleMeetingButton = () => {
        if (edit || ownCollateralManagersCompany) {
            return;
        }

        return (
            <ActionBlocker feature={SubscriptionFeature.ManagerProfileScheduleMeeting}>
                {blocked => (
                    <button
                        disabled={blocked}
                        onClick={() => setShowScheduleMeetingPopup(true)}
                        type="button"
                        className="btn btn-ghost btn-sm btn-schedule-meeting"
                    >
                        <IconSVG name="calendar" width={16} height={16} />
                        Schedule Meeting
                    </button>
                )}
            </ActionBlocker>
        );
    };

    const renderEditButtons = () => {
        if (edit) {
            return (
                <>
                    <button className="btn btn-ghost btn-sm" onClick={onCancel} disabled={isUpdating}>
                        Cancel
                    </button>
                    <button className="btn btn-main btn-sm" onClick={onSave} disabled={isUpdating}>
                        Save
                    </button>
                </>
            );
        }

        return (
            <button className="btn btn-ghost btn-sm" onClick={onEdit}>
                Edit
            </button>
        );
    };

    const renderManagerPresentationButton = (disabled: boolean = false) => {
        return (
            <div className="action-item">
                <Preloader inProgress={isManagerPresentationDownloading} small fullScreen={false} text="Downloading...">
                    <button
                        disabled={!selectedManager?.details?.numberOfManagerPresentationFiles || disabled}
                        onClick={downloadManagerPresentationFile}
                        className="btn-link link-download-docs btn-link-manager-presentation"
                    >
                        <IconSVG name="downloadTemplate" width={16} height={16} />
                        Manager Presentation
                    </button>
                </Preloader>
            </div>
        );
    };

    const renderAlertsSection = () => {
        return (
            <div onClick={e => e.stopPropagation()} className="action-item">
                <CloManagerAlertsButton
                    isAlertOn={isAlertOn}
                    label="Alerts"
                    onClick={() => dispatch(cloManagersActions.setManagerForAlertPopup(company.referenceName))}
                />
            </div>
        );
    };

    const renderManagerPresentationFilesSection = () => {
        const noFiles = !selectedManager?.details?.numberOfManagerPresentationFiles;

        if (noFiles) {
            return null;
        }

        return (
            <ActionBlocker feature={SubscriptionFeature.ManagerProfilePresentations} overrideRequiredFeatures={selectedManager.isUserCompany}>
                {blocked => renderManagerPresentationButton(blocked)}
            </ActionBlocker>
        );
    };

    const defaultHeader = <h1>{company.legalName}</h1>;
    const editHeader = (
        <>
            <h1>Edit Manager</h1>
            <span className="name-tag">{company.legalName}</span>
        </>
    );

    const spacer = (
        <div className="vertical-divider">
            <span></span>
        </div>
    );

    const renderActions = () => {
        if (canEdit) {
            return (
                <>
                    {!edit && renderAlertsSection()}
                    {!edit && renderManagerPresentationFilesSection()}
                    {!edit && spacer}
                    {!edit && renderScheduleMeetingButton()}
                    {renderEditButtons()}
                </>
            );
        }

        return (
            <>
                {renderAlertsSection()}
                <ShowFor roles={roles.getAllRolesExclude(roles.Media, roles.Viewer)}>{renderManagerPresentationFilesSection()}</ShowFor>
                {spacer}
                {renderScheduleMeetingButton()}
            </>
        );
    };

    return (
        <div className="sub-header">
            <div className="sub-header-row type04 flex-row">
                {company.isUserCompany && <div className="sidebar-controls-item"><span className="bwic-state-my">MY</span></div>}
                <CloManagerLabels manager={company} />
                {edit ? editHeader : defaultHeader}
                <div className="flex-item-right flex-row">{renderActions()}</div>
            </div>

            {showScheduleMeetingPopup && (
                <ScheduleMeetingPopup
                    onClose={() => setShowScheduleMeetingPopup(false)}
                    userCompanyName={userCompany!.name}
                    cloManagerReferenceName={selectedManager!.referenceName}
                    cloManagerLegalName={selectedManager!.legalName}
                    defaultEmailList={defaultEmailList}
                />
            )}
        </div>
    );
};

export default Header;
